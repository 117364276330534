import {globalNavigatorProxy} from '@/api/GlobalNavigator';
import {displayStatus} from '@/context/StatusContext';
import ErrorLogger from '@/ErrorLogger';
import {TRPCClientError} from '@trpc/client';
import type {typeToFlattenedError, ZodError} from 'zod';

export type ErrorMeta = XOR<
  {
    status: number;
    message: string;
    form?: typeToFlattenedError<any>['formErrors'];
    field?: typeToFlattenedError<any>['fieldErrors'];
  },
  {
    error: Error;
  }
>;

export function constructErrorMeta(error: Error): ErrorMeta {
  // zod is not resolving to the same `ZodError` when using `instanceof` check in prod for some reason, but works fine in local dev.
  // instead just check constructor name
  // @see https://github.com/colinhacks/zod/issues/2241
  if (error.name === 'ZodError') {
    // zod error from client
    const flattenedError = (error as ZodError).flatten();
    const {formErrors, fieldErrors} = flattenedError;
    return {
      status: 0,
      message: 'Please correct the errors',
      form: formErrors,
      field: fieldErrors,
    };
  } else if (error instanceof TRPCClientError) {
    let errorData = error.data;
    if (!errorData && (error.cause as any)?.response?.body?.error?.json?.data) {
      errorData = (error.cause as any)?.response?.body?.error?.json?.data;
    }
    let meta;
    if (errorData?.httpStatus) {
      meta = {
        status: errorData.httpStatus as number,
        message: errorData.message as string,
      };

      if (errorData.zodError) {
        const {formErrors, fieldErrors} = errorData.zodError as typeToFlattenedError<any>;
        meta.form = formErrors;
        meta.field = fieldErrors;
        if (Object.keys(meta.field).length > 0) {
          meta.message = 'Please correct the errors';
        }
      }
    } else if ((error.cause as any)?.request) {
      const cause = error.cause as any;
      meta = {
        status: cause.response?.status,
        message: cause.response?.statusText || 'An error occurred',
      };
    } else {
      ErrorLogger.Log(error);
      throw error;
    }

    if (meta.status >= 500) {
      ErrorLogger.Log(error);
    }

    return meta;
  } else if (error instanceof Response && error.status >= 300 && error.status < 400) {
    // this is a remix redirect, let it bubble up to allow for redirect
    throw error;
  } else {
    ErrorLogger.Log(error);
    return {
      error,
    };
  }
}

export function displayClientError(errorMeta, options: {fieldErrorMessage?: string} = {}) {
  const {fieldErrorMessage} = options;
  let content: string | undefined;
  if (errorMeta?.error?.data?.message) {
    content = errorMeta.error.data.message;
  } else if (
    fieldErrorMessage &&
    errorMeta?.field &&
    (errorMeta?.status === 400 || errorMeta?.status === 0)
  ) {
    content = fieldErrorMessage;
  } else if ((errorMeta?.status >= 400 && errorMeta?.status < 500) || errorMeta?.status === 0) {
    content = errorMeta.message;
  } else if (errorMeta?.status) {
    content = 'An internal error occurred. Please try again later!';
  }
  if (content) {
    displayStatus({
      type: 'error',
      content,
    });
  }
}

export function handleGenericClientError(error: Error) {
  try {
    const errorMeta = constructErrorMeta(error);
    displayClientError(errorMeta);
    return errorMeta;
  } catch (e) {
    if (e instanceof Response && e.status >= 300 && e.status < 400) {
      const location = e.headers.get('Location')!;
      globalNavigatorProxy.value = location;
      return location;
    }
    ErrorLogger.Log(e as Error);
  }
}
